import React, { Component, Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BannerH4 from '../components/share/banner/bannerH4'
import BannerH6 from '../components/share/banner/bannerH6'
import BrandBanner from '../components/share/brandBanner'
import TypeFormButton from '../components/share/typeFormButton'
import ContentWrapper from '../components/share/contentWrapper'
import FeatureBox from '../components/share/featureBox'
import FooterBox from '../components/share/footerBox'
import GreyBackground from '../components/share/greyBackground'
import ImageBackground from '../components/share/imageBackground'
import IntroRow from '../components/share/introRow'
import Layout from '../components/layout'
import ProductBoxes from '../components/share/productBoxes'
import SpeakToTeam from '../components/share/speakToTeam'

import customMedia from '../utils/customMedia'

const query = graphql`
  query {
    header: file(relativePath: { eq: "campaignManagement/building.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    megaphone: file(relativePath: { eq: "campaignManagement/megaphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    crm: file(relativePath: { eq: "campaignManagement/crm.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    envelop: file(relativePath: { eq: "share/envelop.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    phone: file(relativePath: { eq: "share/phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    management: file(relativePath: { eq: "share/management.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rank: file(relativePath: { eq: "campaignManagement/rank.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    artworktracking: file(
      relativePath: { eq: "campaignManagement/artworktracking.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    service: file(relativePath: { eq: "campaignManagement/service.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    stats: file(relativePath: { eq: "campaignManagement/stats.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    coins: file(relativePath: { eq: "campaignManagement/coins.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jet: file(relativePath: { eq: "campaignManagement/jet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aus: file(relativePath: { eq: "share/aus.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    invoice: file(relativePath: { eq: "share/invoice.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

class CampaignsPage extends Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={query}
          render={data => (
            <Fragment>
              <ImageBackground
                background={data.header.childImageSharp.fluid}
                style={{ height: '600px' }}
                opacity="0.6"
                skew
              >
                <ContentWrapper style={{ flexWrap: 'nowrap' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'home']}
                    style={{ width: '25px', height: '25px', marginBottom: 10 }}
                  />
                  <BannerH6 style={{ marginBottom: 20, textAlign: 'start' }}>
                    Built for You --{' '}
                    <strong>From 1 listing to 500 a year</strong>
                  </BannerH6>
                  <HeaderTitle>Campaign Management</HeaderTitle>

                  <HeaderBody>
                    Realhub does the heavy lifting behind the scenes which means
                    less stress during booking, designing and marketing. Anyone
                    can be an expert and order with Realhub
                  </HeaderBody>

                  <TypeFormButton text="MORE INFO" />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Manage <strong>single campaigns</strong> as easily as you can
                  manage <strong>500 listings</strong>, take control of your
                  office.
                </BannerH4>
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.megaphone.childImageSharp.fluid}
                  title="Get leads, win listings, sell listings, get more leads."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          The complete sales cycle, in one platform. From
                          finding your first lead, to building a real estate
                          empire.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        In this digital age, there’s a million and one
                        subscriptions and technologies aimed at improving the
                        abilities of an agent, and making what previously took
                        huge teams hundreds of hours and thousands of dollars to
                        create, now possible to achieve with smaller teams, and
                        less investment than ever before.
                      </Paragraph>
                      <Paragraph>
                        Finally you can be the agent you’ve always wanted to be,
                        with technology that assists you. Giving you back the
                        time to focus on delivering the personal touch that
                        selling a property requires, without the tedious
                        administrative work.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.crm.childImageSharp.fluid}
                  title="You’re always seconds away from promoting properties."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Campaigns in an instant, build them yourself, or
                          import from your CRM.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Creating and editing campaigns again, and again, and
                        again for each marketing piece of the puzzle is a
                        nightmare. We collect campaign data, and happily export
                        it back out again to marketing providers, so you’ll
                        enter data once, finally.
                      </Paragraph>
                      <Paragraph>
                        Built around your existing CRM, and your existing
                        suppliers we’re an easy to learn conduit that focuses on
                        making your multi-faceted marketing approach’s cohesive,
                        and improving communication between every platform.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <GreyBackground skew>
                <ContentWrapper direction="row">
                  <FeatureBox
                    image={data.envelop.childImageSharp.fluid}
                    contentStyle={{ height: '90px' }}
                    title="Get set up"
                    subTitle="Office/User/Branding/Templates"
                    content="Complete guidance and support throughout onboarding of your business."
                  />
                  <FeatureBox
                    image={data.phone.childImageSharp.fluid}
                    contentStyle={{ height: '90px' }}
                    title="Market"
                    subTitle="Build marketing across digital/print/social"
                    content="Build marketing that makes an impact, and get it produced by any real estate supplier in the country. Minutes from set up."
                  />

                  <FeatureBox
                    image={data.management.childImageSharp.fluid}
                    contentStyle={{ height: '90px' }}
                    title="Find Listings"
                    subTitle="Market yourself, use professional proposals and find sellers"
                    content="Promote your sales success, build agent and agency marketing easily, and fill your sales pipeline."
                  />
                </ContentWrapper>
              </GreyBackground>

              <ContentWrapper>
                <IntroRow
                  image={data.rank.childImageSharp.fluid}
                  title="Control access and roles across your office"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>Powerful tools for managers</strong>
                      </Paragraph>

                      <Paragraph>
                        Creating and editing users, agents, administrators and
                        fine granular control of what exactly users can do on
                        the system is all at your fingertips.
                      </Paragraph>
                      <Paragraph>
                        Tailored to your offices specific workflow, control
                        whether agents can proceed without approved quotes, who
                        sees what and what level of access various users have,
                        with changes taking effect instantly.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.artworktracking.childImageSharp.fluid}
                  title="One trackable source of truth for our whole campaign."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Eliminate risky email proofing and approvals. Powerful
                          team collaboration.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        You don’t know if someone is approving the current
                        artwork or one from 3 revisions ago with email proofing,
                        there’s a huge room for error and when you’re working
                        with artworks like those for newspaper or large run
                        off-set printing the costs of a mistake can be
                        astronomical.
                      </Paragraph>
                      <Paragraph>
                        Don’t risk huge mistakes, use our in-built proofing and
                        artwork approval workflows to ensure that the right
                        version gets approved before sending to suppliers.
                      </Paragraph>
                      <Paragraph>
                        Finally, approvals and commenting in the same place as
                        the artworks. The oversight a manager needs to keep an
                        eye on the entire marketing efforts of an office,
                        available now.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  image={data.service.childImageSharp.fluid}
                  title="Deliver better customer service to your clients"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>SMS Alerts, emails and notifications.</strong>
                      </Paragraph>

                      <Paragraph>
                        Buyers want to know as much about what’s happening with
                        their property campaign as possible, by receiving
                        instant SMS alerts on the progress of campaigns and
                        marketing you’ll be able to keep them happier and offer
                        a more transparent service.
                      </Paragraph>
                      <Paragraph>
                        A happy and educated vendor takes less effort to
                        maintain, with intelligent use of alerts you can reduce
                        your points of contact and avoid any confusion.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ImageBackground
                height="700px"
                opacity="0"
                background={data.jet.childImageSharp.fluid}
              >
                <ContentWrapper style={{ margin: '30px 0' }}>
                  <IntroRow
                    descWidth="60%"
                    title={
                      <Fragment>
                        Faster production of marketing content,
                        <strong>
                          using your data, across multiple channels with
                          automated synchronisation.
                        </strong>
                      </Fragment>
                    }
                    content={
                      <Fragment>
                        <Paragraph>
                          You’ll be creating and delivering pixel-perfect, on
                          brand designs in minutes and sending them to any real
                          estate supplier across Australia in seconds. All the
                          control you need with all the relevant brand
                          restrictions to guide you in keeping control over your
                          marketing and keeping your brand consistent.
                        </Paragraph>
                        <Paragraph>
                          Produce content that spans across newspaper, print,
                          digital and social media while maintaining complete
                          consistency, experience the ease of use and ease of
                          delivery unlike any other platform you’ve ever used.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </ImageBackground>
              <ContentWrapper>
                <IntroRow
                  image={data.stats.childImageSharp.fluid}
                  title="Measure team performances"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Insights help guide your training and staff education
                          to improve the bottom line
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Proposal leaderboards and insights allow you to take
                        control of your listings pipeline and predict income,
                        improve conversion rates and get in front of any issues
                        that may arise.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.coins.childImageSharp.fluid}
                  title="Reduce production costs"
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>Time saved here, can be spent elsewhere</strong>
                      </Paragraph>

                      <Paragraph>
                        There’s already a million and one ways you could be
                        spending your time, both inside work and in your
                        personal life, so don’t spend more time than necessary
                        working back and forward with designers, or liasing with
                        providers.
                      </Paragraph>
                      <Paragraph>
                        Speed up production and time to market, and in turn
                        lowerr the costs to your business significantly.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>
              <GreyBackground>
                <ContentWrapper>
                  <IntroRow
                    image={data.aus.childImageSharp.fluid}
                    title="Your brand, your personality. Not a cookie cutter design."
                    content={
                      <Fragment>
                        <Paragraph>
                          You probably don’t fit the mould of a ‘typical’ agent,
                          or you wouldn’t be here. Everything that got you to
                          where you are today makes you unique, and it is what
                          makes a vendor choose you over the competition.
                        </Paragraph>
                        <Paragraph>
                          Built here in Australia we can offer completely custom
                          one-off templates that perfectly fit your brand. You
                          can have this designed by our design team, your
                          professional third party design studio, or our
                          talented front-end designers. No one will confuse you
                          with the competition.
                        </Paragraph>
                      </Fragment>
                    }
                  />
                </ContentWrapper>
              </GreyBackground>
              <ContentWrapper>
                <IntroRow
                  reverse
                  image={data.invoice.childImageSharp.fluid}
                  title="Provide accurate and transparent pricing."
                  content={
                    <Fragment>
                      <Paragraph>
                        <strong>
                          Become the accounting team’s new best friend.
                        </strong>
                      </Paragraph>

                      <Paragraph>
                        Accidentally underquoting a realestate.com.au listing,
                        or over quoting a newspaper ad could cost you or the
                        vendor thousands, and lead to unprofessional
                        conversations to smooth over. Don’t leave pricing up to
                        chance, use a marketing platform with your pricing and
                        quoting built in.
                      </Paragraph>
                      <Paragraph>
                        Once a quote is approved, it can quickly and easily be
                        turned into order items, ready to create artworks for.
                      </Paragraph>
                    </Fragment>
                  }
                />
              </ContentWrapper>

              <ContentWrapper style={{ alignItems: 'center' }}>
                <BannerH4>
                  Get <strong>every real estate service</strong> in{' '}
                  <strong>one place</strong>, and save hours communicating and
                  tracking entire campaigns.
                </BannerH4>

                <TypeFormButton />
              </ContentWrapper>
              <ContentWrapper>
                <ProductBoxes />
              </ContentWrapper>
              <ContentWrapper>
                <FlexColumn style={{ alignItems: 'center' }}>
                  <BannerH4>
                    Our <strong>loyal team</strong> of{' '}
                    <strong>Australia’s leading</strong> real estate agencies,
                    working <strong>hand in hand</strong> to help build the{' '}
                    <strong>future of real estate.</strong>
                  </BannerH4>
                  <BannerH6 style={{ marginBottom: 0 }}>
                    Input from the largest and smallest real estate businesses
                    in Australia is helping to build the most effective platform
                    for professional agents.
                  </BannerH6>
                </FlexColumn>
              </ContentWrapper>

              <ContentWrapper>
                <SpeakToTeam />
              </ContentWrapper>
              <ContentWrapper>
                <BrandBanner />
              </ContentWrapper>
              <ContentWrapper direction="row">
                <FooterBox name="migration" />

                <FooterBox name="feetUp" />
              </ContentWrapper>
            </Fragment>
          )}
        />
      </Layout>
    )
  }
}

export default CampaignsPage

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 680px;
    font-size: 53px;
  `};
`

const HeaderBody = styled.p`
  font-size: 14px;
  font-weight: 300;
  font-family: Open Sans;
  line-height: 1.5;
  margin-bottom: 30px;

  ${customMedia.greaterThan('mobile')`
    max-width: 450px;
  `};
`

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
